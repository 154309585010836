body {
	background-color: #336699;
}

.password-assistance {
	padding-top: 20px;
	text-align: center;
}

.instructions {
	background-color: #ffffcc;
	border-radius: 5px;
	border: 1px solid #cccccc;
	color: #666666;
	padding: 5px;
}

.form-group-wrapper {
	padding-top: 25px;
}

.staging-warning {
	background-color: #ffcccc;
	border-radius: 3px;
	color: #ff0000;
	line-height: normal;
	margin: 0 0 25px 0;
	padding: 5px;
	text-align: center;
}

.system-requirements {
	color: #ffffff;
	font-size: 10px;
	margin-bottom: 20px;
	text-align: center;
}

.copyright {
	color: #ffffff;
}
