body {
	background: #f5f5f5;
}

.container {
	padding: 60px 0;
	min-width: 360px;
}

.domain {
	margin-bottom: 24px;
	text-align: center;
}

.logo {
	margin-bottom: 24px;
	text-align: center;
}

.form-container {
	background: #ffffff;
	border-radius: 6px;
	border: 1px solid #e5e5e5;
	margin: 24px auto;
	max-width: 479px;
	padding: 24px;
}

.btn-default {
	background: #eeeeee;

	&:hover {
		background: #e5e5e5;
	}
}

.copyright {
	text-align: center;
}

.environment {
	margin-top: 24px;
	text-align: center;

	.label-localhost {
		background-color: #777777;
	}

	.label-development {
		background-color: #468847;
	}

	.label-staging {
		background-color: #f89406;
	}

	.label-production {
		background-color: #b94a48;
	}
}

.password-assistance {
	text-align: center;
	padding: 2.5rem 0.5rem 0.5rem 0.5rem;
}

.instructions {
	padding: 5px;
	background-color: #ffffcc;
	border: 1px solid #cccccc;
	border-radius: 5px;
	color: #666666;
	font-size: 85%;
}

.form-group-wrapper {
	padding-top: 25px;
}
